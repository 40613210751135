import { Option, Select } from '@conteg/ui';
import { SelectWrapper } from 'components/styled/kiosk-wrappers';
import { IS_KIOSK } from 'config';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  LockStatus,
  StorageUnitFeature,
  StorageUnitStatus,
} from 'types/generated/graphql';
import ConditionalWrapper from 'utils/conditional-wrapper/conditional-wrapper';

// Create a union type for valid filter options
type StorageUnitFilterOptions =
  | 'ALL'
  | 'EMPTY'
  | 'OCCUPIED'
  | StorageUnitFeature.AcSocket
  | StorageUnitFeature.Cooling
  | LockStatus.Opened
  | StorageUnitStatus.Blocked
  | StorageUnitStatus.Broken
  | StorageUnitStatus.Usable
  | 'ServiceModule';

// Define the enum with the valid options
export const StorageUnitFilterOptionsEnum: Record<
  StorageUnitFilterOptions,
  StorageUnitFilterOptions
> = {
  ALL: 'ALL',
  EMPTY: 'EMPTY',
  OCCUPIED: 'OCCUPIED',
  ServiceModule: 'ServiceModule',
  AC_SOCKET: StorageUnitFeature.AcSocket,
  COOLING: StorageUnitFeature.Cooling,
  OPENED: LockStatus.Opened,
  BLOCKED: StorageUnitStatus.Blocked,
  BROKEN: StorageUnitStatus.Broken,
  USABLE: StorageUnitStatus.Usable,
};

export type StorageUnitFilterValues =
  | StorageUnitFilterOptions
  | StorageUnitStatus
  | LockStatus
  | StorageUnitFeature;

type DataGridFilterProps = {
  selectedStatus: StorageUnitFilterValues;
  setStatus: React.Dispatch<React.SetStateAction<StorageUnitFilterValues>>;
};

const DataGridFilter = ({ selectedStatus, setStatus }: DataGridFilterProps) => {
  const { t } = useTranslation();

  const [filterOptions] = useState<Option[]>([
    {
      value: StorageUnitFilterOptionsEnum.ALL,
      label: t('Page.StorageUnitList.Filter.All'),
    },
    {
      value: StorageUnitFilterOptionsEnum.EMPTY,
      label: t('Page.StorageUnitList.Filter.Available'),
    },
    {
      value: StorageUnitFilterOptionsEnum.OCCUPIED,
      label: t('Page.StorageUnitList.Filter.Occupied'),
    },
    {
      value: LockStatus.Opened,
      label: t('Page.StorageUnitList.Filter.Unlocked'),
    },
    {
      value: StorageUnitFeature.AcSocket,
      label: t('Page.StorageUnitList.Filter.AcSocket'),
    },
    {
      value: StorageUnitFeature.Cooling,
      label: t('Page.StorageUnitList.Filter.Cooling'),
    },
    {
      value: StorageUnitFilterOptionsEnum.ServiceModule,
      label: t('Page.StorageUnitList.Filter.ServiceModule'),
    },
  ]);

  return (
    <ConditionalWrapper
      condition={IS_KIOSK}
      wrap={(children: ReactNode) => <SelectWrapper>{children}</SelectWrapper>}
    >
      <div id="select-container">
        <Select
          testId="storage-units-filter"
          label={t('Page.StorageUnitList.Filter.Label')}
          options={filterOptions}
          defaultSingleValue={StorageUnitFilterOptionsEnum.ALL}
          onSingleValueChange={(value: string | null) =>
            setStatus((value as StorageUnitFilterValues) ?? selectedStatus)
          }
        />
      </div>
    </ConditionalWrapper>
  );
};

export default DataGridFilter;
